<template>
  <div>
    <!-- begin::Card -->
    <div class="card card-custom gutter-b">
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            Asignación de grupos académicos y técnicos
            <span class="d-block text-muted pt-2 font-size-sm">
              Aqui se podra asignar las secciones y grupo académicos a los
              estudiantes de todos los grados que han completado el proceso de
              matriculación. A los de nivel académico bachillerato se les podrá
              asignar grupo técnico.
            </span>
          </h3>
        </div>
        <div class="card-toolbar"></div>
      </div>
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col-12">
            <v-alert
              prominent
              text
              :type="
                !!students.filters.grade_id && !!students.filters.speciality_id
                  ? 'success'
                  : 'warning'
              "
            >
              <div
                v-if="
                  !!students.filters.grade_id &&
                    !!students.filters.speciality_id
                "
              >
                El
                <span class="font-weight-bold">grado</span> y la
                <span class="font-weight-bold">especialidad</span>
                han sido seleccionados.
              </div>
              <div v-else>
                Debes seleccionar un
                <span class="font-weight-bold">grado</span> y una
                <span class="font-weight-bold">especialidad</span> para poder
                hacer la selección múltiple de estudiantes.
              </div>
            </v-alert>
          </div>
        </div>
        <!-- begin::Filters -->
        <v-card class="mb-4">
          <div class="text-h5 font-weight-medium px-4 pt-4">Filtros</div>
          <v-divider></v-divider>
          <div class="row align-items-center mx-4 my-4">
            <div class="col-lg-12">
              <div class="row align-items-center">
                <div class="col-12">
                  <v-text-field
                    solo
                    light
                    hide-details
                    prepend-icon=""
                    prepend-inner-icon="mdi-magnify"
                    placeholder="Buscar..."
                    v-model="students.search"
                  ></v-text-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 my-2 my-md-0 py-md-0">
                  <div class="d-flex align-items-center">
                    <v-checkbox
                      v-model="checkbox"
                      @change="loadStudents"
                      label="Mostrar estudiantes que no tienen grupos asignados
                      "
                    ></v-checkbox>
                  </div>
                </div>
              </div>
              <div class="row align-items-center mb-5">
                <div class="col-md-6 my-2 my-md-0">
                  <div class="d-flex align-items-center">
                    <v-select
                      label="Seleccionar grado"
                      solo
                      light
                      hide-details
                      clearable
                      :items="grades"
                      item-text="name"
                      item-value="id"
                      @input="cleanSelection"
                      v-model="students.filters.grade_id"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" small v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{ students.filters.grade_id.length - 1 }}
                          seleccionados)
                        </span>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="col-md-6 my-2 my-md-0">
                  <div class="d-flex align-items-center">
                    <v-select
                      label="Seleccionar especialidad"
                      solo
                      light
                      hide-details
                      clearable
                      :items="specialities"
                      item-text="name"
                      item-value="id"
                      @input="cleanSelection"
                      v-model="students.filters.speciality_id"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" small v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{ students.filters.speciality_id.length - 1 }}
                          seleccionados)
                        </span>
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card>
        <!-- end::Filters -->
        <v-expand-transition>
          <div
            class="row align-items-center"
            v-if="
              !!students.filters.grade_id && !!students.filters.speciality_id
            "
          >
            <div class="col-12">
              <v-alert
                prominent
                text
                :type="selectedStudents.length > 0 ? 'success' : 'warning'"
              >
                <div v-if="selectedStudents.length > 0">
                  Has seleccionado
                  <span class="font-weight-bold">{{
                    selectedStudents.length == 1
                      ? `${selectedStudents.length} estudiante.`
                      : `${selectedStudents.length} estudiantes.`
                  }}</span>
                </div>
                <div v-else>
                  Debes seleccionar
                  <span class="font-weight-bold"
                    >por lo menos a un estudiante</span
                  >
                  para que el botón de asignar grupo académico se habilite.
                </div>
              </v-alert>
            </div>
          </div>
        </v-expand-transition>
        <!--begin: Datatable-->
        <v-card>
          <v-card-title>
            Estudiantes
            <v-spacer></v-spacer>
            <!-- begin::Export menu -->
            <v-menu
              offset-y
              bottom
              transition="slide-y-transition"
              :disabled="!getFileName || filteredData.length == 0"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="0"
                  color="purple darken-4"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                  dark
                >
                  Exportar
                  <v-icon right dark>
                    mdi-cloud-upload
                  </v-icon>
                </v-btn>
              </template>
              <!-- begin::v-list -->
              <v-list>
                <download-excel
                  class="btn btn-default"
                  :data="filteredData"
                  :fields="students.json_fields"
                  worksheet="Listado de estudiantes"
                  :name="getFileName"
                >
                  <v-list-item link>
                    <v-list-item-title
                      ><v-icon color="green" left>mdi-file-excel</v-icon>
                      Excel</v-list-item-title
                    >
                  </v-list-item>
                </download-excel>
              </v-list>
              <!-- end::v-list -->
            </v-menu>
            <!-- end::Export menu -->
            <!--begin::Button New Record-->
            <v-btn
              elevation="0"
              color="primary"
              :disabled="selectedStudents.length == 0"
              @click="showMdl('mdlGroupAssignment')"
              v-if="existInArray('Asignar grupo', currentPageActions)"
            >
              <span class="svg-icon svg-icon-md svg-icon-white mr-1">
                <inline-svg
                  src="media/svg/icons/Home/Book-open.svg"
                ></inline-svg>
              </span>
              Asignar grupo
            </v-btn>
            <!--end::Button New Record-->
          </v-card-title>
          <v-data-table
            :headers="students.headers"
            :items="filteredData"
            :search="students.search"
            :items-per-page="10"
            :loading="students.isLoading"
            v-model="selectedStudents"
            item-key="id"
            :show-select="
              !!students.filters.grade_id && !!students.filters.speciality_id
            "
            checkbox-color="primary"
          >
            <!-- begin: Actions -->
            <template v-slot:[`item.actions`]="{ item }">
              <template>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="
                        selectStudent(item);
                        showMdlStudentInformation();
                      "
                      color="blue"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information
                    </v-icon>
                  </template>
                  <span>Ver más información</span>
                </v-tooltip>
              </template>
            </template>
            <!-- end: Acions -->
          </v-data-table>
        </v-card>
        <!-- end: Datatable-->
      </div>
    </div>
    <!-- end::Card -->

    <!-- begin::Modals -->
    <StudentInformationModal
      ref="mdlStudentInformation"
      @reload-data="loadStudents"
      :student="selectedStudent"
    ></StudentInformationModal>

    <GroupAssignmentModal
      ref="mdlGroupAssignment"
      @reload-data="loadStudents"
      @clean-selection="cleanSelection"
      @clean-filters="cleanFilters"
      :students="selectedStudents"
      :grade_id="students.filters.grade_id"
      :speciality_id="students.filters.speciality_id"
      :hasTechnicalGroups="
        students.filters.grade_id
          ? !!getGradeById(students.filters.grade_id).academic_level
              .has_technical_groups
          : false
      "
    ></GroupAssignmentModal>
    <!-- end::Modals -->
  </div>
</template>

<script>
import studentRepository from "@/repositories/studentRepository";
import gradeRepository from "@/repositories/gradeRepository";
import specialityRepository from "@/repositories/specialityRepository";
import StudentInformationModal from "@/components/modals/students/StudentInformationModal.vue";
import GroupAssignmentModal from "@/components/modals/group_assignments/GroupAssignmentModal.vue";
import academicGroupRepository from "@/repositories/academicGroupRepository";
import technicalGroupRepository from "@/repositories/technicalGroupRepository";
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

export default {
  name: "GroupAssignments",
  title: "Asignación de grupos | GE ITR",
  components: {
    StudentInformationModal,
    GroupAssignmentModal,
  },
  data() {
    return {
      students: {
        headers: [
          { text: "Nombres", value: "first_name" },
          { text: "Apellidos", value: "last_name" },
          { text: "Código", value: "code" },
          { text: "Correo institucional", value: "email" },
          { text: "Grado", value: "grade" },
          { text: "Especialidad", value: "speciality" },
          { text: "Grupo académico", value: "section_group" },
          { text: "Grupo técnico", value: "technical_group" },
          { text: "Modalidad", value: "modality" },
          { text: "Acciones", value: "actions", sortable: false },
        ],
        json_fields: {
          Código: "code",
          Nombres: "first_name",
          Apellidos: "last_name",
          "Correo institucional": "email",
          Grado: "grade",
          Especialidad: "speciality",
          "Grupo académico": "section_group",
          "Grupo técnico": "technical_group",
          Modalidad: "modality",
        },
        data: [],
        search: "",
        isLoading: false,
        filters: {
          grade_id: null,
          speciality_id: null,
        },
      },
      selectedStudent: {},
      academicGroups: [],
      technicalGroups: [],
      selectedStudents: [],
      grades: [],
      specialities: [],
      checkbox: false,
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Asignación de grupos", route: "group_assignments" },
    ]);
    this.loadStudents();
    this.getAcademicGroups();
    this.getTechnicalGroups();
    this.getGrades();
    this.getSpecialities();
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Asignación de grupos"); // Change
  },
  methods: {
    selectStudent(obj) {
      // Creating a non reactive copie of data
      this.selectedStudent = { ...obj };
    },
    unselectStudent() {
      this.selectedStudent = {};
    },
    showMdlStudentInformation() {
      this.$refs.mdlStudentInformation.toggleModal();
    },
    showMdl(mdlRef) {
      try {
        this.$refs[mdlRef].toggleModal();
      } catch (err) {
        console.error(
          `No existe ningún modal con la referencia "${mdlRef}", asegurate de haberlo registrado correctamente`
        );
      }
    },
    loadStudents() {
      this.students.isLoading = true;
      if (!this.checkbox) {
        studentRepository
          .getAllStudentsWithEnrollmentProcess()
          .then(({ data }) => {
            this.students.data = data;
          })
          .catch((err) => {
            this.fireToast({
              icon: "error",
              title: "No ha sido posible cargar los datos desde el servidor",
            });
          })
          .finally(() => {
            this.students.isLoading = false;
          });
      } else {
        studentRepository
          .getAllStudentsHasNotGroups()
          .then(({ data }) => {
            this.students.data = data;
          })
          .catch((err) => {
            this.fireToast({
              icon: "error",
              title: "No ha sido posible cargar los datos desde el servidor",
            });
          })
          .finally(() => {
            this.students.isLoading = false;
          });
      }
    },
    deleteStudent() {
      studentRepository
        .deleteStudent(this.selectedStudent.id)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data if operation has been completed
          if (data.status) {
            this.loadStudents();
          }
        })
        .catch((error) => {
          // Sweetalert that indicates the operation was unsuccesfull
          this.fireToast({
            icon: "error",
            title: `No es posible eliminar el registro ${this.selectedStudent.name} en estos momentos 🙁`,
          });
        });
    },
    changeStatusStudent() {
      studentRepository
        .changeStatusStudent(this.selectedStudent.id)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data if operation has been completed
          if (data.status) {
            this.loadStudents();
          }
        })
        .catch((error) => {
          // Sweetalert that indicates the operation was unsuccesfull
          this.fireToast({
            icon: "error",
            title: `No es posible cambiar el estado del registro seleccionado en estos momentos 🙁`,
          });
        });
    },
    getAcademicGroups() {
      academicGroupRepository
        .getAllAcademicGroups()
        .then(({ data }) => {
          this.academicGroups = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },
    getTechnicalGroups() {
      technicalGroupRepository
        .getAllTechnicalGroups()
        .then(({ data }) => {
          this.technicalGroups = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },
    getGrades() {
      gradeRepository
        .getAllGrades()
        .then(({ data }) => {
          this.grades = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },
    getSpecialities() {
      specialityRepository
        .getAllSpecialities()
        .then(({ data }) => {
          this.specialities = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },
    getGradeById(grade_id) {
      return this.grades.find((element) => element.id == grade_id);
    },
    cleanSelection() {
      this.selectedStudents = [];
    },
    cleanFilters() {
      this.students.filters = {};
    },
  },
  computed: {
    ...mapGetters(["currentPageActions"]),
    filteredData() {
      return this.filterData(this.students.filters, this.students.data);
    },
    getFileName() {
      const grade = this.grades.find(
        (grade) => grade.id == this.students.filters.grade_id
      );
      const speciality = this.specialities.find(
        (speciality) => speciality.id == this.students.filters.speciality_id
      );
      const date = new Date().toISOString().slice(0, 10);

      return `Listado de estudiantes de ${
        grade ? grade.name : "todos los grados"
      } y especialidad ${
        !!speciality ? speciality.name : "(sin seleccionar)"
      } - ${date}.xls`;
    },
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>
