<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    transition="dialog-bottom-transition"
    max-width="700"
  >
    <v-form>
      <v-card>
        <v-toolbar dark color="pink" class="elevation-0 px-3">
          <span class="headline"
            >Confirmación de asignación másiva de estudiantes</span
          >
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-alert text outlined type="info" class="m-0">
                  Antes de asignarles el grupo, corrobora la información.
                </v-alert>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="text-body-1">
                  A los
                  <span class="text--primary font-weight-medium"
                    >{{ students.length }} estudiantes</span
                  >
                  seleccionados serán ubicados en la
                  <span class="text--primary font-weight-medium">
                    sección {{ academicGroup.name }}</span
                  >
                  de
                  <span
                    v-if="students.length > 0"
                    class="text--primary font-weight-medium"
                    >{{ students[0].grade }}</span
                  >
                  <div v-if="technicalGroup.id">
                    y formaran parte del
                    <span class="text--primary font-weight-medium"
                      >Grupo {{ technicalGroup.groups.name }}</span
                    >
                    de
                    <span
                      v-if="students.length > 0"
                      class="text--primary font-weight-medium"
                      >{{ technicalGroup.speciality.name }}.</span
                    >
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <v-divider class="mx-2"></v-divider>
          <v-container>
            <v-row>
              <v-col class="pt-0">
                <span class="text-h6 mx-2">Estudiantes seleccionados</span>
              </v-col>
            </v-row>
          </v-container>
          <v-card class="mx-2">
            <v-list-item-group color="pink">
              <template v-for="(student, index) in students">
                <v-list-item :key="`item-${student.id}`">
                  <v-list-item-avatar>
                    <v-avatar color="blue" size="56" class="white--text">
                      <v-img v-if="student.img" :src="student.img"></v-img>
                      <v-icon v-else color="white">
                        mdi-account
                      </v-icon>
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ `${student.first_name} ${student.last_name}` }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <span class="text--primary font-weight-medium mr-1">{{
                        student.code
                      }}</span>
                      <v-icon small color="pink darken-2">
                        mdi-email
                      </v-icon>
                      {{ student.email }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <!-- <v-list-item-action>
                    <v-btn depressed text small @click="removeStudent(student)">
                      Quitar
                      <v-icon color="blue" right>
                        mdi-account-remove
                      </v-icon>
                    </v-btn>
                  </v-list-item-action> -->
                </v-list-item>

                <v-divider
                  :key="`divider-${index}`"
                  class="my-1 mr-5"
                  inset
                  v-if="index < students.length"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-card>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end pt-0">
          <v-btn text outlined class="mr-1" @click="cancel">Regresar</v-btn>
          <v-btn
            color="pink"
            depressed
            dark
            :loading="isLoading"
            @click="submit"
            >Asignar grupos</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import studentRepository from "@/repositories/studentRepository";

export default {
  name: "ConfirmGroupAssignmentModal",
  data() {
    return {
      dialog: false,
      isLoading: false,
    };
  },
  props: {
    students: {
      type: Array,
    },
    academicGroup: {
      type: Object,
    },
    technicalGroup: {
      type: Object,
    },
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
    cancel() {
      this.toggleModal();
      this.$emit("go-previous");
    },
    removeStudent(student) {
      const index = this.students.indexOf(student);
      this.students = this.students.splice(index, 1);
    },
    submit() {
      this.isLoading = true;
      // Se crea un array con el id de los estudiantes
      const studentsIdsArr = this.students.map((item) => item.id);
      const payload = {
        grade_section_group_id: this.academicGroup.id,
        grade_speciality_group_id: this.technicalGroup.id,
        students: studentsIdsArr,
      };
      studentRepository
        .groupsAssignment(payload)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          this.dialog = false;
          // Retrieving data and hidding modal if operation has been completed
          if (data.status) {
            this.$emit("clean-modal");
          }
        })
        .catch((err) => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible la asignación másiva 🙁",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
