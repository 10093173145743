<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      scrollable
      persistent
      max-width="525"
    >
      <v-form>
        <v-card>
          <v-toolbar dark color="deep-orange" class="elevation-0 px-3">
            <span class="headline">Grupo académico</span>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <span class="text-body-1">
                    {{
                      hasTechnicalGroups
                        ? "Selecciona el grupo académico y el grupo técnico"
                        : "Selecciona el grupo académico"
                    }}
                  </span>
                </v-col>
              </v-row>
            </v-container>
            <v-divider class="mx-4 my-0"></v-divider>
            <v-container>
              <!-- begin::Academic groups List -->
              <v-row>
                <v-col class="pb-0">
                  <span class="text-h6">Grupos académicos</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-radio-group
                    v-model="selectedAcademicGroup"
                    v-for="(academicGroups, section) in gradeAcademicGroups"
                    :key="`section-${section}`"
                  >
                    <template v-slot:label>
                      <span class="text-body-1">Secciones "{{ section }}"</span>
                    </template>
                    <v-radio
                      v-for="academicGroup in academicGroups"
                      :key="`aG-${academicGroup.id}`"
                      :label="academicGroup.name"
                      :value="academicGroup"
                      color="deep-orange"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <!-- end::Academic groups List -->
              <!-- begin::Technical groups List -->
              <v-row v-if="technicalGroups.length > 0">
                <v-col class="pb-0">
                  <span class="text-h6">Grupos técnicos</span>
                </v-col>
              </v-row>
              <v-row v-if="technicalGroups.length > 0">
                <v-col>
                  <v-radio-group v-model="selectedTechnicalGroup">
                    <v-radio
                      v-for="technicalGroup in technicalGroups"
                      :key="`tG-${technicalGroup.id}`"
                      :value="technicalGroup"
                      color="deep-orange"
                    >
                      <template v-slot:label>
                        <div class="text-body-1">
                          {{ `${technicalGroup.speciality.name} ` }}
                          <span class="text-body-1 font-weight-medium">
                            {{ `Grupo ${technicalGroup.groups.name}` }}
                          </span>
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <!-- end::Technical groups List -->
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end pt-0">
            <v-btn text outlined class="mr-1" @click="closeModal"
              >Cancelar</v-btn
            >
            <v-btn
              color="deep-orange"
              depressed
              dark
              @click="showMdlConfirmation"
              >Siguiente</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <ConfirmGroupAssignmentModal
      ref="mdlConfirmation"
      :students="students"
      :academicGroup="selectedAcademicGroup"
      :technicalGroup="selectedTechnicalGroup"
      @go-previous="toggleModal"
      @clean-modal="cleanModal"
    ></ConfirmGroupAssignmentModal>
  </div>
</template>

<script>
import academicGroupRepository from "@/repositories/academicGroupRepository";
import technicalGroupRepository from "@/repositories/technicalGroupRepository";
import ConfirmGroupAssignmentModal from "@/components/modals/group_assignments/ConfirmGroupAssignmentModal.vue";

export default {
  name: "GroupAssignmentModal",
  components: {
    ConfirmGroupAssignmentModal,
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      gradeAcademicGroups: {},
      selectedAcademicGroup: {},
      technicalGroups: [],
      selectedTechnicalGroup: {},
    };
  },
  props: {
    students: {
      type: Array,
    },
    grade_id: {
      type: Number,
    },
    speciality_id: {
      type: Number,
    },
    hasTechnicalGroups: {
      type: Boolean,
    },
  },

  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },

    closeModal() {
      this.toggleModal();
      this.resetData();
    },

    resetData() {
      this.gradeAcademicGroups = [];
      this.technicalGroups = [];
      this.selectedAcademicGroup = {};
      this.selectedTechnicalGroup = {};
    },

    cleanModal() {
      this.resetData();
      this.$emit("clean-selection");
      this.$emit("clean-filters");
      this.$emit("reload-data");
    },

    getAcademicGroupsOfGrade(id) {
      academicGroupRepository
        .getAcademicGroupsByGrade(id)
        .then(({ data }) => {
          // Agrupa los grupos academicos por secciones en un objecto donde la key es la sección y el valor es un array con todos los grupos academicos
          const dataGroupedBySection = data.reduce((accumulator, current) => {
            if (!accumulator[current.section]) {
              accumulator[current.section] = [];
            }
            accumulator[current.section].push({
              id: current.id,
              name: `${current.section}-${current.group}`,
            });
            return accumulator;
          }, {});
          this.gradeAcademicGroups = dataGroupedBySection;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },

    getTechnicalGroupsOfGrade(id) {
      technicalGroupRepository
        .getTechnicalGroupsByGrade(id)
        .then(({ data }) => {
          const filteredData = data.filter(
            (item) => item.speciality_id == this.speciality_id
          );
          this.technicalGroups = filteredData;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },

    showMdlConfirmation() {
      if (
        Object.keys(this.selectedAcademicGroup).length == 0 &&
        !this.hasTechnicalGroups
      ) {
        thG.fireToast({
          icon: "error",
          title: "Antes de continuar debes seleccionar un grupo académico",
        });
        return;
      }
      if (
        this.hasTechnicalGroups &&
        (Object.keys(this.selectedAcademicGroup).length == 0 ||
          Object.keys(this.selectedTechnicalGroup).length == 0)
      ) {
        this.fireToast({
          icon: "error",
          title:
            "Antes de continuar debes seleccionar un grupo académico y técnico",
        });
        return;
      }
      this.toggleModal();
      this.$refs.mdlConfirmation.toggleModal();
    },
  },

  watch: {
    dialog(newValue, oldValue) {
      if (newValue) {
        this.getAcademicGroupsOfGrade(this.grade_id);
        if (this.hasTechnicalGroups) {
          this.getTechnicalGroupsOfGrade(this.grade_id);
        }
      }
    },
  },
};
</script>
